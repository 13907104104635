import axios from 'axios'

export default {
  actions: {
    reportsTotalsRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/reports/totals'
        const response = await axios.get(url, data)
        commit('setReportsTotals', response.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    reportsTotalsExportRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/reports/totals/export'
        const response = await axios.get(url, data)
        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    }
  },
  mutations: {
    setReportsTotals: (state, data) => {
      state.reportsTotalsData = data.data
      state.reportsTotalsMeta = data.meta
    }
  },
  getters: {
    getReportsTotalsData: state => state.reportsTotalsData,
    getReportsTotalsMeta: state => state.reportsTotalsMeta
  },
  state: {
    reportsTotalsData: [],
    reportsTotalsMeta: {}
  }
}
