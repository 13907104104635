import axios from 'axios'

export default {
  actions: {
    dashboardRequest: async ({ commit }) => {
      try {
        const url = '/admin/dashboard'
        const response = await axios.get(url)
        commit('setDashboard', response.data.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    }
  },
  mutations: {
    setDashboard: (state, data) => {
      state.dashboard = data
    }
  },
  getters: {
    getDashboard: state => state.dashboard
  },
  state: {
    dashboard: {
      clientsByMonths: {},
      ordersByMonths: {},
      months: []
    }
  }
}
