import axios from 'axios'

export default {
  actions: {
    inselectClientRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/clients/inselect'
        const response = await axios.post(url, data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    usersRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/users?page=' + data.page
        const response = await axios.get(url)
        commit('setUsersData', response.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    editAdminUserRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/users/' + data.id + '/edit'
        const response = await axios.get(url)
        commit('setSingleAdminUser', response.data.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    editClientRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/clients/' + data.id + '/edit'
        const response = await axios.get(url)
        commit('setSingleClient', response.data.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    saveClientRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/clients/' + data.id
        const response = await axios.put(url, data.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    saveAdminUserRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/users/' + data.id
        const response = await axios.put(url, data.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    showRoleListRequest: async ({ commit }) => {
      try {
        const url = '/admin/users/create'
        const response = await axios.get(url)
        commit('setRoleList', response.data.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    createAdminUserRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/users'
        const response = await axios.post(url, data.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    deleteAdminUserRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/users/' + data.id
        const response = await axios.delete(url, data.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    clientsRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/clients?page=' + data.page
        const response = await axios.get(url)
        commit('setClientsData', response.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    profileRequest: async ({ commit }) => {
      try {
        const url = '/admin/users/get-user-info'
        const response = await axios.get(url)
        commit('setProfileData', response.data.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    }
  },
  mutations: {
    setUsersData: (state, data) => {
      state.usersData = data.data
      state.usersMeta = data.meta
    },
    setClientsData: (state, data) => {
      state.clientsData = data.data
      state.clientsMeta = data.meta
    },
    setProfileData: (state, data) => {
      state.profile = data.user
    },
    setSingleAdminUser: (state, data) => {
      state.singleAdminUser = data.user
      state.roleList = data.roles
    },
    setSingleClient: (state, data) => {
      state.singleClient = data.client
      state.cashbackTypes = data.cashbackTypes
    },
    setRoleList: (state, data) => {
      state.roleList = data.roles
    }
  },
  getters: {
    getUsersData: state => state.usersData,
    getUsersMeta: state => state.usersMeta,
    getClientsData: state => state.clientsData,
    getProfileData: state => state.profile,
    getClientsMeta: state => state.clientsMeta,
    getSingleAdminUser: state => state.singleAdminUser,
    getRoleList: state => state.roleList,
    getSingleClient: state => state.singleClient,
    getCashbackTypes: state => state.cashbackTypes
  },
  state: {
    usersData: [],
    usersMeta: {},
    profile: {},
    clientsData: [],
    clientsMeta: {},
    singleAdminUser: {},
    roleList: [],
    singleClient: {},
    cashbackTypes: []
  }
}
