import axios from 'axios'

export default {
  actions: {
    localesRequest: async ({ commit }, data) => {
      try {
        const url = '/locales'
        const response = await axios.get(url, data)
        commit('setLocalesData', response.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    }
  },
  mutations: {
    setLocalesData: (state, data) => {
      state.localesData = data.data
    }
  },
  getters: {
    getLocalesData: state => state.localesData
  },
  state: {
    localesData: []
  }
}
