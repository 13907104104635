import axios from 'axios'

export default {
  actions: {
    metroStationsRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/metro-stations?page=' + data.page
        const response = await axios.get(url)
        commit('setMetroStationsData', response.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    searchMetroStationsRequest: async ({ commit }, data) => {
      try {
        const url = `/admin/metro-stations?page=${data.page}&search=${data.search}`
        const response = await axios.get(url, data)
        commit('setMetroStationsData', response.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    editMetroStationRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/metro-stations/' + data.id + '/edit'
        const response = await axios.get(url)
        commit('setMetroStationData', response.data.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    saveMetroStationRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/metro-stations/' + data.id
        const response = await axios.put(url, data.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    createMetroStationRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/metro-stations'
        const response = await axios.post(url, data.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    deleteMetroStationRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/metro-stations/' + data.id
        const response = await axios.delete(url)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    metroStationsAllRequest: async ({ commit }) => {
      try {
        const url = 'metro-stations'
        const response = await axios.get(url)
        commit('setMetroStationsAll', response.data)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    }
  },
  mutations: {
    setMetroStationsData: (state, data) => {
      state.metroStationsData = data.data
      state.metroStationsMeta = data.meta
    },
    setMetroStationData: (state, data) => {
      state.metroStationData = data
    },
    setMetroStationsAll: (state, data) => {
      state.metroStationsAll = data.data
    }
  },
  getters: {
    getMetroStationsData: state => state.metroStationsData,
    getMetroStationsMeta: state => state.metroStationsMeta,
    getMetroStationData: state => state.metroStationData,
    getMetroStationsAll: (state) => state.metroStationsAll
  },
  state: {
    metroStationsData: [],
    metroStationsMeta: {},
    metroStationData: {},
    metroStationsAll: []
  }
}
