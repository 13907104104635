import axios from 'axios'

export default {
  actions: {
    categoriesRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/categories'
        const response = await axios.get(url, data)
        commit('setCategories', response.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    subcategoriesRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/categories/subcategories'
        const response = await axios.get(url, data)
        commit('setSubcategories', response.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    addingProductsRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/products/hidden-products-subcategory?page=' + data.page
        const response = await axios.get(url)
        commit('setAddingProducts', response.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    deleteCategoryRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/categories/' + data.id
        const response = await axios.delete(url)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    deleteCategoryExternalImageRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/categories/' + data.id + '/external-image'
        const response = await axios.delete(url)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    editCategoryRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/categories/' + data.id + '/edit'
        const response = await axios.get(url)
        commit('setEditCategory', response.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    editSubcategoryRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/categories/' + data.id + '/edit'
        const response = await axios.get(url)
        commit('setSubcategory', response.data.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    saveRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/categories/' + data.id
        let response
        if (data.headers) {
          response = await axios.post(url, data.data, data.headers)
        } else {
          response = await axios.put(url, data.data)
        }

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    createSubcategoryRequest: async ({ commit }) => {
      try {
        const url = '/admin/categories/create'
        const response = await axios.get(url)
        commit('setCreateSubcategory', response.data.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    calcSlugCategory: async ({ commit }, data) => {
      try {
        const url = '/admin/categories/calc_slug'
        const response = await axios.post(url, data)
        return Promise.resolve(response.data.data.code)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    createCategoryRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/categories'
        let response
        if (data.headers) {
          response = await axios.post(url, data.data, data.headers)
        } else {
          response = await axios.post(url, data.data)
        }

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    upCategoryRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/categories/' + data.id + '/up'
        const response = await axios.get(url)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    downCategoryRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/categories/' + data.id + '/down'
        const response = await axios.get(url)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    positionCategoryRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/categories/' + data.id + '/position/' + data.position
        const response = await axios.get(url)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    }
  },
  mutations: {
    setCategories: (state, data) => {
      state.categoriesList = data.data
      state.categoriesMeta = data.meta
    },
    setSubcategories: (state, data) => {
      state.subcategoriesList = data.data
      state.subcategoriesMeta = data.meta
    },
    setHiddenSubcategories: (state, data) => {
      state.hiddenSubcategoriesList = data.data
      state.hiddenSubcategoriesMeta = data.meta
    },
    setEditCategory: (state, data) => {
      state.categoryData = data.data.category
      state.subcategoriesList = data.data.subcategories
    },
    setSubcategory: (state, data) => {
      state.subcategoryData = data.category
      state.categoriesList = data.categories
      state.productsList = data.products
    },
    setCreateSubcategory: (state, data) => {
      state.categoriesList = data.categories
      state.productsList = data.products
    },
    setAddingProducts: (state, data) => {
      state.addingProducts = data.data
      state.addingProductsMeta = data.meta
    }
  },
  getters: {
    getCategories: state => state.categoriesList,
    getCategoriesMeta: state => state.categoriesMeta,

    getSubcategories: state => state.subcategoriesList,
    getSubcategoriesMeta: state => state.subcategoriesMeta,
    getHiddenSubcategories: state => state.hiddenSubcategoriesList,
    getHiddenSubcategoriesMeta: state => state.hiddenSubcategoriesMeta,

    getCategoryData: state => state.categoryData,
    getSubcategoryData: state => state.subcategoryData,

    getProductsList: state => state.productsList,

    getAddingProducts: state => state.addingProducts,
    getAddingProductsMeta: state => state.addingProductsMeta
  },
  state: {
    categoriesList: [],
    categoriesMeta: {},

    subcategoriesList: [],
    subcategoriesMeta: {},

    hiddenSubcategoriesList: [],
    hiddenSubcategoriesMeta: {},

    categoryData: {
      name: {
        en: '',
        ru: '',
        ua: ''
      },
      parent: {
        id: null,
        name: {
          ru: ''
        }
      },
      categories: []
    },
    subcategoryData: {
      name: {
        en: '',
        ru: '',
        ua: ''
      }
    },
    productsList: [],

    addingProducts: [],
    addingProductsMeta: {}
  }
}
