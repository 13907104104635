import axios from 'axios'

export default {
  actions: {
    deliverySchedulesRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/delivery-schedules?page=' + data.page
        const response = await axios.get(url)
        commit('setDeliverySchedulesData', response.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    editDeliveryScheduleRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/delivery-schedules/' + data.id + '/edit'
        const response = await axios.get(url)
        commit('setDeliveryScheduleData', response.data.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    saveDeliveryScheduleRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/delivery-schedules/' + data.id
        const response = await axios.put(url, data.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    createDeliveryScheduleRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/delivery-schedules'
        const response = await axios.post(url, data.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    deleteDeliveryScheduleRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/delivery-schedules/' + data.id
        const response = await axios.delete(url)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    showDeliveryScheduleCitiesRequest: async ({ commit }) => {
      try {
        const url = '/admin/delivery-schedules/create'
        const response = await axios.get(url)
        commit('setDeliveryScheduleCities', response.data.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    }
  },
  mutations: {
    setDeliverySchedulesData: (state, data) => {
      state.deliverySchedulesData = data.data
      state.deliverySchedulesMeta = data.meta
    },
    setDeliveryScheduleData: (state, data) => {
      state.deliveryScheduleData = data.delivery_schedule
      state.deliveryScheduleCities = data.cities
    },
    setDeliveryScheduleCities: (state, data) => {
      state.deliveryScheduleCities = data.cities
    }
  },
  getters: {
    getDeliverySchedulesData: state => state.deliverySchedulesData,
    getDeliverySchedulesMeta: state => state.deliverySchedulesMeta,
    getDeliveryScheduleData: state => state.deliveryScheduleData,
    getDeliveryScheduleCities: state => state.deliveryScheduleCities
  },
  state: {
    deliverySchedulesData: [],
    deliverySchedulesMeta: {},
    deliveryScheduleData: {},
    deliveryScheduleCities: []
  }
}
