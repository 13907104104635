import axios from 'axios'

export default {
  actions: {
    sharesAllRequest: async ({ commit }) => {
      try {
        const url = '/admin/shares/all'
        const response = await axios.get(url)
        commit('setSharesData', response.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    sharesRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/shares'
        const response = await axios.get(url, data)
        commit('setSharesData', response.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    searchSharesRequest: async ({ commit }, data) => {
      try {
        const url = `/admin/shares?page=${data.page}&search=${data.search}`
        const response = await axios.get(url, data)
        commit('setSharesSearchData', response.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    editShareRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/shares/' + data.id + '/edit'
        const response = await axios.get(url)
        commit('setSingleShareData', response.data.data)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    saveShareRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/shares/' + data.id
        const response = await axios.post(url, data.data, data.headers)
        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    createShareRequest: async ({ commit }) => {
      try {
        const url = '/admin/shares/create'
        const response = await axios.get(url)
        commit('setShareCategoriesList', response.data.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    postShareRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/shares'
        let response
        if (data.headers) {
          response = await axios.post(url, data.data, data.headers)
        } else {
          response = await axios.post(url, data.data)
        }

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    deleteShareRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/shares/' + data.id
        const response = await axios.delete(url)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    deleteShareImage: async ({ commit }, data) => {
      try {
        const url = '/admin/shares/' + data.id + '/image'
        const response = await axios.delete(url)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    upShareRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/shares/' + data.id + '/up'
        const response = await axios.get(url)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    downShareRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/shares/' + data.id + '/down'
        const response = await axios.get(url)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    positionShareRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/shares/' + data.id + '/position/' + data.position
        const response = await axios.get(url)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    calcSlugShare: async ({ commit }, data) => {
      try {
        const url = '/admin/shares/calc_slug'
        const response = await axios.post(url, data)
        return Promise.resolve(response.data.data.code)
      } catch (error) {
        return Promise.reject(error)
      }
    }
  },
  mutations: {
    setSharesData: (state, data) => {
      state.sharesData = data.data
      state.sharesMeta = data.meta
    },
    setSharesSearchData: (state, data) => {
      state.sharesSearchData = data.data
      state.sharesSearchMeta = data.meta
    },
    setSingleShareData: (state, data) => {
      state.singleShare = data.share
      state.categoriesList = data.categories
    },
    setShareCategoriesList: (state, data) => {
      state.categoriesList = data.categories
    }
  },
  getters: {
    getSharesData: state => state.sharesData,
    getSharesSearchData: state => state.sharesSearchData,
    getSharesMeta: state => state.sharesMeta,
    getSingleShareData: state => state.singleShare,
    getShareCategoriesList: state => state.categoriesList
  },
  state: {
    sharesData: [],
    sharesMeta: {},
    sharesSearchData: [],
    sharesSearchMeta: {},
    singleShare: {
      name: {
        en: '',
        ru: '',
        ua: ''
      },
      description: {
        en: '',
        ru: '',
        ua: ''
      },
      category: {
        id: null,
        name: {
          ru: null
        }
      },
      subcategory: {
        id: null,
        name: {
          ru: null
        }
      }
    },
    categoriesList: []
  }
}
