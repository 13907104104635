import axios from 'axios'

export default {
  actions: {
    editSettingsRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/settings/' + data.id + '/edit'
        const response = await axios.get(url)
        commit('setSettingData', response.data.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    saveSettingsRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/settings/'
        const response = await axios.put(url, data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    createSettingsRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/settings'
        const response = await axios.post(url, data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    deleteSettingsRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/settings/' + data.id
        const response = await axios.delete(url)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    }
  },
  mutations: {
    setSettingData: (state, data) => {
      state.settingsData = data.data
    }
  },
  getters: {
    getSettingsData: state => state.metroStationsData
  },
  state: {
    settingsData: {}
  }
}
