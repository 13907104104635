<template>
  <b-navbar toggleable="lg" :variant="getLayoutNavbarBg()" class="layout-navbar align-items-lg-center container-p-x">

    <!-- Brand demo (see demo.css) -->
    <b-navbar-brand to="/" class="app-brand demo d-lg-none py-0 mr-4">
      <span class="app-brand-logo demo bg-primary">
        <svg viewBox="0 0 148 80" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><linearGradient id="a" x1="46.49" x2="62.46" y1="53.39" y2="48.2" gradientUnits="userSpaceOnUse"><stop stop-opacity=".25" offset="0"></stop><stop stop-opacity=".1" offset=".3"></stop><stop stop-opacity="0" offset=".9"></stop></linearGradient><linearGradient id="e" x1="76.9" x2="92.64" y1="26.38" y2="31.49" xlink:href="#a"></linearGradient><linearGradient id="d" x1="107.12" x2="122.74" y1="53.41" y2="48.33" xlink:href="#a"></linearGradient></defs><path style="fill: #fff;" transform="translate(-.1)" d="M121.36,0,104.42,45.08,88.71,3.28A5.09,5.09,0,0,0,83.93,0H64.27A5.09,5.09,0,0,0,59.5,3.28L43.79,45.08,26.85,0H.1L29.43,76.74A5.09,5.09,0,0,0,34.19,80H53.39a5.09,5.09,0,0,0,4.77-3.26L74.1,35l16,41.74A5.09,5.09,0,0,0,94.82,80h18.95a5.09,5.09,0,0,0,4.76-3.24L148.1,0Z"></path><path transform="translate(-.1)" d="M52.19,22.73l-8.4,22.35L56.51,78.94a5,5,0,0,0,1.64-2.19l7.34-19.2Z" fill="url(#a)"></path><path transform="translate(-.1)" d="M95.73,22l-7-18.69a5,5,0,0,0-1.64-2.21L74.1,35l8.33,21.79Z" fill="url(#e)"></path><path transform="translate(-.1)" d="M112.73,23l-8.31,22.12,12.66,33.7a5,5,0,0,0,1.45-2l7.3-18.93Z" fill="url(#d)"></path></svg>
      </span>
      <span class="app-brand-text demo font-weight-normal ml-2">Pies@City</span>
    </b-navbar-brand>

    <!-- Sidenav toggle (see demo.css) -->
    <b-navbar-nav class="layout-sidenav-toggle d-lg-none align-items-lg-center mr-auto" v-if="sidenavToggle">
      <a class="nav-item nav-link px-0 mr-lg-4" href="javascript:void(0)" @click="toggleSidenav">
        <i class="ion ion-md-menu text-large align-middle" />
      </a>
    </b-navbar-nav>

    <b-navbar-toggle target="app-layout-navbar"></b-navbar-toggle>

    <b-collapse is-nav id="app-layout-navbar">
      <!-- Divider -->
      <hr class="d-lg-none w-100 my-2">

      <b-navbar-nav class="align-items-lg-center">
        <!-- Search -->
<!--        <label class="nav-item navbar-text navbar-search-box p-0 active">-->
<!--          <i class="ion ion-ios-search navbar-icon align-middle"></i>-->
<!--          <span class="navbar-search-input pl-2">-->
<!--            <input type="text" class="form-control navbar-text mx-2" placeholder="Search..." style="width:200px">-->
<!--          </span>-->
<!--        </label>-->
      </b-navbar-nav>

      <b-navbar-nav class="align-items-lg-center ml-auto">
<!--        <b-nav-item-dropdown no-caret :right="!isRtlMode" class="demo-navbar-notifications mr-lg-3">-->
<!--          <template slot="button-content">-->
<!--            <i class="ion ion-md-notifications-outline navbar-icon align-middle"></i>-->
<!--            <span class="badge badge-primary badge-dot indicator"></span>-->
<!--            <span class="d-lg-none align-middle">&nbsp; Notifications</span>-->
<!--          </template>-->

<!--          <div class="bg-primary text-center text-white font-weight-bold p-3">-->
<!--            4 New Notifications-->
<!--          </div>-->
<!--          <b-list-group flush>-->
<!--            <b-list-group-item href="javascript:void(0)" class="media d-flex align-items-center">-->
<!--              <div class="ui-icon ui-icon-sm ion ion-md-home bg-secondary border-0 text-white"></div>-->
<!--              <div class="media-body line-height-condenced ml-3">-->
<!--                <div class="text-body">Login from 192.168.1.1</div>-->
<!--                <div class="text-light small mt-1">-->
<!--                  Aliquam ex eros, imperdiet vulputate hendrerit et.-->
<!--                </div>-->
<!--                <div class="text-light small mt-1">12h ago</div>-->
<!--              </div>-->
<!--            </b-list-group-item>-->

<!--            <b-list-group-item href="javascript:void(0)" class="media d-flex align-items-center">-->
<!--              <div class="ui-icon ui-icon-sm ion ion-md-person-add bg-info border-0 text-white"></div>-->
<!--              <div class="media-body line-height-condenced ml-3">-->
<!--                <div class="text-body">You have <strong>4</strong> new followers</div>-->
<!--                <div class="text-light small mt-1">-->
<!--                  Phasellus nunc nisl, posuere cursus pretium nec, dictum vehicula tellus.-->
<!--                </div>-->
<!--              </div>-->
<!--            </b-list-group-item>-->

<!--            <b-list-group-item href="javascript:void(0)" class="media d-flex align-items-center">-->
<!--              <div class="ui-icon ui-icon-sm ion ion-md-power bg-danger border-0 text-white"></div>-->
<!--              <div class="media-body line-height-condenced ml-3">-->
<!--                <div class="text-body">Server restarted</div>-->
<!--                <div class="text-light small mt-1">-->
<!--                  19h ago-->
<!--                </div>-->
<!--              </div>-->
<!--            </b-list-group-item>-->

<!--            <b-list-group-item href="javascript:void(0)" class="media d-flex align-items-center">-->
<!--              <div class="ui-icon ui-icon-sm ion ion-md-warning bg-warning border-0 text-body"></div>-->
<!--              <div class="media-body line-height-condenced ml-3">-->
<!--                <div class="text-body">99% server load</div>-->
<!--                <div class="text-light small mt-1">-->
<!--                  Etiam nec fringilla magna. Donec mi metus.-->
<!--                </div>-->
<!--                <div class="text-light small mt-1">-->
<!--                  20h ago-->
<!--                </div>-->
<!--              </div>-->
<!--            </b-list-group-item>-->
<!--          </b-list-group>-->

<!--          <a href="javascript:void(0)" class="d-block text-center text-light small p-2 my-1">Show all notifications</a>-->
<!--        </b-nav-item-dropdown>-->

<!--        <b-nav-item-dropdown no-caret :right="!isRtlMode" class="demo-navbar-messages mr-lg-3">-->
<!--          <template slot="button-content">-->
<!--            <i class="ion ion-ios-mail navbar-icon align-middle"></i>-->
<!--            <span class="badge badge-primary badge-dot indicator"></span>-->
<!--            <span class="d-lg-none align-middle">&nbsp; Messages</span>-->
<!--          </template>-->

<!--          <div class="bg-primary text-center text-white font-weight-bold p-3">-->
<!--            4 New Messages-->
<!--          </div>-->
<!--          <b-list-group flush>-->
<!--            <b-list-group-item href="javascript:void(0)" class="media d-flex align-items-center">-->
<!--              <img :src="`${publicUrl}img/avatars/6-small.png`" class="d-block ui-w-40 rounded-circle" alt>-->
<!--              <div class="media-body ml-3">-->
<!--                <div class="text-body line-height-condenced">Sit meis deleniti eu, pri vidit meliore docendi ut.</div>-->
<!--                <div class="text-light small mt-1">-->
<!--                  Mae Gibson &nbsp;·&nbsp; 58m ago-->
<!--                </div>-->
<!--              </div>-->
<!--            </b-list-group-item>-->

<!--            <b-list-group-item href="javascript:void(0)" class="media d-flex align-items-center">-->
<!--              <img :src="`${publicUrl}img/avatars/4-small.png`" class="d-block ui-w-40 rounded-circle" alt>-->
<!--              <div class="media-body ml-3">-->
<!--                <div class="text-body line-height-condenced">Mea et legere fuisset, ius amet purto luptatum te.</div>-->
<!--                <div class="text-light small mt-1">-->
<!--                  Kenneth Frazier &nbsp;·&nbsp; 1h ago-->
<!--                </div>-->
<!--              </div>-->
<!--            </b-list-group-item>-->

<!--            <b-list-group-item href="javascript:void(0)" class="media d-flex align-items-center">-->
<!--              <img :src="`${publicUrl}img/avatars/5-small.png`" class="d-block ui-w-40 rounded-circle" alt>-->
<!--              <div class="media-body ml-3">-->
<!--                <div class="text-body line-height-condenced">Sit meis deleniti eu, pri vidit meliore docendi ut.</div>-->
<!--                <div class="text-light small mt-1">-->
<!--                  Nelle Maxwell &nbsp;·&nbsp; 2h ago-->
<!--                </div>-->
<!--              </div>-->
<!--            </b-list-group-item>-->

<!--            <b-list-group-item href="javascript:void(0)" class="media d-flex align-items-center">-->
<!--              <img :src="`${publicUrl}img/avatars/11-small.png`" class="d-block ui-w-40 rounded-circle" alt>-->
<!--              <div class="media-body ml-3">-->
<!--                <div class="text-body line-height-condenced">Lorem ipsum dolor sit amet, vis erat denique in, dicunt prodesset te vix.</div>-->
<!--                <div class="text-light small mt-1">-->
<!--                  Belle Ross &nbsp;·&nbsp; 5h ago-->
<!--                </div>-->
<!--              </div>-->
<!--            </b-list-group-item>-->
<!--          </b-list-group>-->

<!--          <a href="javascript:void(0)" class="d-block text-center text-light small p-2 my-1">Show all messages</a>-->
<!--        </b-nav-item-dropdown>-->

        <!-- Divider -->
        <div class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1">|</div>

        <b-nav-item-dropdown :right="!isRtlMode" class="demo-navbar-user">
          <template slot="button-content">
            <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
              <img :src="`${publicUrl}img/avatars/avatar-manager.png`" alt class="d-block ui-w-30 rounded-circle">
              <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{ getProfileData.full_name }}</span>
            </span>
          </template>

          <b-dd-item :disabled="getProfileData && getProfileData.role === 'manager'" :href="`/admin-user/${getProfileData.id}`">
            <i class="ion ion-ios-person text-lightest" /> &nbsp; Мой профиль
          </b-dd-item>
<!--          <b-dd-item><i class="ion ion-ios-mail text-lightest"></i> &nbsp; Messages</b-dd-item>-->
<!--          <b-dd-item><i class="ion ion-md-settings text-lightest"></i> &nbsp; Account settings</b-dd-item>-->
          <b-dd-divider />
          <b-dd-item @click="logout">
            <i class="ion ion-ios-log-out text-danger" />
            &nbsp; Log Out
          </b-dd-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'app-layout-navbar',
  props: {
    sidenavToggle: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters({
      getProfileData: 'getProfileData'
    })
  },
  methods: {
    ...mapActions({
      authLogout: 'authLogout'
    }),
    logout () {
      this.authLogout().then(() => this.$router.push('/login'))
    },
    toggleSidenav () {
      this.layoutHelpers.toggleCollapsed()
    },
    getLayoutNavbarBg () {
      return this.layoutNavbarBg
    }
  }
}
</script>
