import axios from 'axios'

export default {
  actions: {
    paymentsRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/payments'
        const response = await axios.get(url, data)
        commit('setPaymentsData', response.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    searchPaymentsRequest: async ({ commit }, data) => {
      try {
        const url = `/admin/payments?page=${data.page}&search=${data.search}`
        const response = await axios.get(url, data)
        commit('setPaymentsSearchData', response.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    editPaymentRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/payments/' + data.id + '/edit'
        const response = await axios.get(url)
        commit('setSinglePaymentData', response.data.data)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    savePaymentRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/payments/' + data.id
        const response = await axios.post(url, data.data, data.headers)
        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    postPaymentRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/payments'
        let response
        if (data.headers) {
          response = await axios.post(url, data.data, data.headers)
        } else {
          response = await axios.post(url, data.data)
        }

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    deletePaymentRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/payments/' + data.id
        const response = await axios.delete(url)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    }
  },
  mutations: {
    setPaymentsData: (state, data) => {
      state.paymentsData = data.data
      state.paymentsMeta = data.meta
    },
    setPaymentsSearchData: (state, data) => {
      state.paymentsSearchData = data.data
      state.paymentsSearchMeta = data.meta
    },
    setSinglePaymentData: (state, data) => {
      state.singlePayment = data.payment
    }
  },
  getters: {
    getPaymentsData: state => state.paymentsData,
    getPaymentsSearchData: state => state.paymentsSearchData,
    getPaymentsMeta: state => state.paymentsMeta,
    getSinglePaymentData: state => state.singlePayment
  },
  state: {
    paymentsData: [],
    paymentsMeta: {},
    paymentsSearchData: [],
    paymentsSearchMeta: {},
    singlePayment: {
      name: {
        en: '',
        ru: '',
        ua: ''
      }
    }
  }
}
