import axios from 'axios'

export default {
  actions: {
    deliveriesAllRequest: async ({ commit }) => {
      try {
        const url = '/deliveries'
        const response = await axios.get(url)
        commit('setDeliveriesAll', response.data)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    paymentsAllRequest: async ({ commit }) => {
      try {
        const url = '/payments'
        const response = await axios.get(url)
        commit('setPaymentsAll', response.data)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    paymentStatusesAllRequest: async ({ commit }) => {
      try {
        const url = '/payment-statuses'
        const response = await axios.get(url)
        commit('setPaymentStatusesAll', response.data)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    createGuestOrder: async ({ commit }, data) => {
      try {
        const url = '/guest-orders'
        const response = await axios.post(url, data)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    createClientOrder: async ({ commit }, data) => {
      try {
        const url = '/client/orders'
        const response = await axios.post(url, data)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    }
  },
  mutations: {
    setDeliveriesAll: (state, data) => {
      state.deliveriesAll = data.data
    },
    setPaymentsAll: (state, data) => {
      state.paymentsAll = data.data
    },
    setPaymentStatusesAll: (state, data) => {
      state.paymentStatusesAll = data.data
    }
  },
  getters: {
    getDeliveriesAll: (state) => state.deliveriesAll,
    getPaymentsAll: (state) => state.paymentsAll,
    getPaymentStatusesAll: (state) => state.paymentStatusesAll
  },
  state: {
    deliveriesAll: [],
    paymentsAll: [],
    paymentStatusesAll: []
  }
}
