import axios from 'axios'

export default {
  actions: {
    citiesRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/cities?page=' + data.page
        const response = await axios.get(url)
        commit('setCitiesData', response.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    editCityRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/cities/' + data.id + '/edit'
        const response = await axios.get(url)
        commit('setCityData', response.data.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    saveCityRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/cities/' + data.id
        const response = await axios.put(url, data.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    createCityRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/cities'
        const response = await axios.post(url, data.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    deleteCityRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/cities/' + data.id
        const response = await axios.delete(url)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    citiesAllWithRestaurantsRequest: async ({ commit }) => {
      try {
        const url = '/cities-with-restaurants'
        const response = await axios.get(url)
        commit('setCitiesAllWithRestaurants', response.data)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    citiesAllWithDeliveryScheduleRequest: async ({ commit }) => {
      try {
        const url = '/cities-with-delivery_schedules'
        const response = await axios.get(url)
        commit('setCitiesAllWithDeliverySchedule', response.data)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    }
  },
  mutations: {
    setCitiesData: (state, data) => {
      state.citiesData = data.data
      state.citiesMeta = data.meta
    },
    setCityData: (state, data) => {
      state.cityData = data.city
    },
    setCitiesAllWithRestaurants: (state, data) => {
      state.citiesAllWithRestaurants = data.data
    },
    setCitiesAllWithDeliverySchedule: (state, data) => {
      state.citiesAllWithDeliverySchedule = data.data
    }
  },
  getters: {
    getCitiesData: state => state.citiesData,
    getCitiesMeta: state => state.citiesMeta,
    getCityData: state => state.cityData,
    getCitiesAllWithRestaurants: state => state.citiesAllWithRestaurants,
    getCitiesAllWithDeliverySchedule: (state) =>
      state.citiesAllWithDeliverySchedule
  },
  state: {
    citiesData: [],
    citiesMeta: {},
    cityData: {},
    citiesAllWithRestaurants: [],
    citiesAllWithDeliverySchedule: []
  }
}
