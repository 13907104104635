import axios from 'axios'

export default {
  actions: {
    cashbackRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/cashbacks?page=' + data.page
        const response = await axios.get(url)
        commit('setCashbackList', response.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    removeCashbackRequest: async ({ commit }, id) => {
      try {
        const url = '/admin/cashbacks/' + id
        const response = await axios.delete(url)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    editCashbackRequest: async ({ commit }, id) => {
      try {
        const url = '/admin/cashbacks/' + id + '/edit'
        const response = await axios.get(url)
        commit('setSingleCashback', response.data.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    createCashbackRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/cashbacks'
        const response = await axios.post(url, data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    saveCashbackRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/cashbacks/' + data.id
        const response = await axios.patch(url, data.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    }
  },
  mutations: {
    setCashbackList: (state, data) => {
      state.cashbackList = data.data
      state.cashbackMeta = data.meta
    },
    setSingleCashback: (state, data) => {
      state.singleCashback = data.cashback
    }
  },
  getters: {
    getCashbackList: state => state.cashbackList,
    getCashbackMeta: state => state.cashbackMeta,
    getSingleCashback: state => state.singleCashback
  },
  state: {
    cashbackList: [],
    cashbackMeta: {},
    singleCashback: {}
  }
}
