import axios from 'axios'

export default {
  actions: {
    shareBannerRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/share_banner/0'
        const response = await axios.get(url, data)
        commit('setShareBannersData', response.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    shareBannerSaveRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/share_banner/0'
        const response = await axios.post(url, data.data, data.headers)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    shareBannerDeleteRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/share_banner/0'
        const response = await axios.delete(url, data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    }
  },
  mutations: {
    setShareBannersData: (state, data) => {
      state.shareBannerData = data.data
    }
  },
  getters: {
    getShareBannerData: state => state.shareBannerData
  },
  state: {
    shareBannerData: {
      is_show: false,
      title: {},
      text: {},
      text_upper: {},
      image_desktop: null,
      image_tablet: null,
      image_mobile: null,
      image_on_product: null,
      url: null,
      category_id: null,
      subcategory_id: null
    }
  }
}
