import axios from 'axios'

export default {
  actions: {
    aboutUsPageRequest: async ({ commit }) => {
      try {
        const url = '/admin/about-us'
        const response = await axios.get(url)
        commit('setAboutUsData', response.data.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    updateAboutUsPageRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/about-us/' + data.id
        let response
        if (data.headers) {
          response = await axios.post(url, data.data, data.headers)
        } else {
          response = await axios.patch(url, data.data)
        }

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    publicContractsPageRequest: async ({ commit }) => {
      try {
        const url = '/admin/public-contracts'
        const response = await axios.get(url)
        commit('setPublicContractData', response.data.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    updatePublicContractsPageRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/public-contracts/' + data.id
        const response = await axios.patch(url, data.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    aboutDeliveryPageRequest: async ({ commit }) => {
      try {
        const url = '/admin/delivery-page'
        const response = await axios.get(url)
        commit('setAboutDeliveryData', response.data.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    updateAboutDeliveryPageRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/delivery-page/' + data.id
        let response
        if (data.headers) {
          response = await axios.post(url, data.data, data.headers)
        } else {
          response = await axios.patch(url, data.data)
        }

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    bonusAccountPageRequest: async ({ commit }) => {
      try {
        const url = '/admin/bonuses'
        const response = await axios.get(url)
        commit('setBonusAccountData', response.data.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    bonusAccountDeliveryPageRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/bonuses/' + data.id
        const response = await axios.patch(url, data.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    }
  },
  mutations: {
    setAboutUsData: (state, data) => {
      state.aboutUsData = data
    },
    setPublicContractData: (state, data) => {
      state.publicContractData = data
    },
    setAboutDeliveryData: (state, data) => {
      state.aboutDeliveryData = data
    },
    setBonusAccountData: (state, data) => {
      state.bonusAccountData = data
    }
  },
  getters: {
    getAboutUsData: state => state.aboutUsData,
    getPublicContractData: state => state.publicContractData,
    getAboutDeliveryData: state => state.aboutDeliveryData,
    getBonusAccountData: state => state.bonusAccountData
  },
  state: {
    aboutUsData: {
      description: {
        ru: '',
        en: '',
        ua: ''
      }
    },
    publicContractData: {
      description: {
        ru: '',
        ua: '',
        en: ''
      }
    },
    aboutDeliveryData: {
      content: {
        ru: '',
        ua: '',
        en: ''
      }
    },
    bonusAccountData: {
      description: {
        ru: '',
        ua: '',
        en: ''
      }
    }
  }
}
