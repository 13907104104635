import axios from 'axios'

export default {
  actions: {
    authLogin: async ({ commit }, data) => {
      try {
        const url = '/auth/admin/login'
        const response = await axios.post(url, data)
        const token = response.data.data.token
        // eslint-disable-next-line
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
        localStorage.setItem('pf_user_token', token)
        commit('setAuthSuccess', token)

        return Promise.resolve(response.data)
      } catch (error) {
        localStorage.removeItem('pf_user_token')
        return Promise.reject(error)
      }
    },
    async authLogout ({ commit }) {
      try {
        commit('setAuthLogout')
        localStorage.removeItem('pf_user_token')
        // eslint-disable-next-line
        delete axios.defaults.headers.common['Authorization']

        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      }
    }
  },
  mutations: {
    setAuthSuccess: (state, data) => {
      // state.roles = data.roles
      state.token = 'Bearer ' + data.token
      state.status = 'success'
    },
    setAuthLogout: (state) => {
      // state.roles = []
      state.token = ''
      state.status = ''
    }
  },
  getters: {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.status
  },
  state: {
    // roles: [],
    token: localStorage.getItem('pf_user_token') || '',
    status: ''
  }
}
