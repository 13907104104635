import axios from 'axios'

export default {
  actions: {
    productsAllRequest: async ({ commit }) => {
      try {
        const url = '/admin/products/all'
        const response = await axios.get(url)
        commit('setProductsData', response.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    productsRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/products'
        const response = await axios.get(url, data)
        commit('setProductsData', response.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    searchProductsRequest: async ({ commit }, data) => {
      try {
        const url = `/admin/products?page=${data.page}&search=${data.search}`
        const response = await axios.get(url, data)
        commit('setProductsSearchData', response.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    editProductRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/products/' + data.id + '/edit'
        const response = await axios.get(url)
        commit('setSingleProductData', response.data.data)

        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    saveProductRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/products/' + data.id
        const response = await axios.post(url, data.data, data.headers)
        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    createProductRequest: async ({ commit }) => {
      try {
        const url = '/admin/products/create'
        const response = await axios.get(url)
        commit('setProductCategoriesList', response.data.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    postProductRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/products'
        let response
        if (data.headers) {
          response = await axios.post(url, data.data, data.headers)
        } else {
          response = await axios.post(url, data.data)
        }

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    deleteProductRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/products/' + data.id
        const response = await axios.delete(url)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    deleteProductImage: async ({ commit }, data) => {
      try {
        const url = '/admin/products/' + data.id + '/image'
        const response = await axios.delete(url)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    upProductRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/products/' + data.id + '/up'
        const response = await axios.get(url)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    downProductRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/products/' + data.id + '/down'
        const response = await axios.get(url)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    positionProductRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/products/' + data.id + '/position/' + data.position
        const response = await axios.get(url)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    calcSlugProduct: async ({ commit }, data) => {
      try {
        const url = '/admin/products/calc_slug'
        const response = await axios.post(url, data)
        return Promise.resolve(response.data.data.code)
      } catch (error) {
        return Promise.reject(error)
      }
    }
  },
  mutations: {
    setProductsData: (state, data) => {
      state.productsData = data.data
      state.productsMeta = data.meta
    },
    setProductsSearchData: (state, data) => {
      state.productsSearchData = data.data
      state.productsSearchMeta = data.meta
    },
    setSingleProductData: (state, data) => {
      state.singleProduct = data.product
      state.categoriesList = data.categories
      state.productWeightData = data.weight
    },
    setProductCategoriesList: (state, data) => {
      state.categoriesList = data.categories
      state.productWeightData = data.weights
    }
  },
  getters: {
    getProductsData: state => state.productsData,
    getProductsSearchData: state => state.productsSearchData,
    getProductsMeta: state => state.productsMeta,
    getSingleProductData: state => state.singleProduct,
    getProductCategoriesList: state => state.categoriesList,
    getProductWeightData: state => state.productWeightData
  },
  state: {
    productsData: [],
    productsMeta: {},
    productsSearchData: [],
    productsSearchMeta: {},
    singleProduct: {
      name: {
        en: '',
        ru: '',
        ua: ''
      },
      description: {
        en: '',
        ru: '',
        ua: ''
      },
      weight_resource: {
        id: null,
        name: null
      },
      category: {
        id: null,
        name: {
          ru: null
        }
      },
      subcategory: {
        id: null,
        name: {
          ru: null
        }
      }
    },
    categoriesList: [],
    productWeightData: []
  }
}
