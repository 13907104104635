<template>
  <div id="app">
    <router-view/>
    <notifications
      group="request"
      position="bottom right"
    />
    <notifications
      group="auth"
      position="bottom right"
    />
  </div>
</template>

<style src="@/vendor/styles/bootstrap.scss" lang="scss"></style>
<style src="@/vendor/styles/appwork.scss" lang="scss"></style>
<style src="@/vendor/styles/theme-corporate.scss" lang="scss"></style>
<style src="@/vendor/styles/colors.scss" lang="scss"></style>
<style src="@/vendor/styles/uikit.scss" lang="scss"></style>
<style src="./style.scss" lang="scss"></style>

<script>
import axios from 'axios'

export default {
  name: 'app',
  metaInfo: {
    title: 'Index',
    titleTemplate: '%s - Pies@City'
  },
  created: function () {
    axios.interceptors.response.use(undefined, function (err) {
      return new Promise(function (resolve, reject) {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          // if you ever get an unauthorized, logout the user
          this.$store.dispatch('authLogout')
          // you can also redirect to /login if needed !
          this.$router.push('/login')
        } else if (err.status === 404) {
          this.$router.push('/NotFound')
        }
        throw err
      })
    })
  },
  updated () {
    // Remove loading state
    setTimeout(() => document.body.classList.remove('app-loading'), 1)
  }
}
</script>
