import axios from 'axios'

export default {
  actions: {
    ordersStatisticsRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/orders/statistics'
        const response = await axios.get(url, data)
        commit('setOrdersStatistics', response.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    ordersListRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/orders'
        const response = await axios.get(url, data)
        commit('setOrdersList', response.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    editOrderRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/orders/' + data.id + '/edit-order'
        const response = await axios.get(url)
        commit('setOrder', response.data)
        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    saveSingleOrderRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/orders/' + data.id + '/status'
        const response = await axios.patch(url, data.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    deleteSingleOrderRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/orders/' + data.id
        const response = await axios.delete(url)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    orderStatusesAllRequest: async ({ commit }) => {
      try {
        const url = '/admin/order-statuses'
        const response = await axios.get(url)
        commit('setOrderStatusesAll', response.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    updateOrder: async ({ commit }, data) => {
      try {
        const url = '/admin/orders/' + data.id
        const response = await axios.patch(url, data.data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    createOrder: async ({ commit }, data) => {
      try {
        const url = '/admin/orders'
        const response = await axios.post(url, data)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    calculateCostDeliveryRequest: async ({ commit }, data) => {
      try {
        const url = '/admin/calculate-cost-delivery'
        const response = await axios.post(url, data)
        commit('setCostDelivery', response.data)

        return Promise.resolve(response)
      } catch (error) {
        commit('setCostDelivery', { data: 0 })
        return Promise.reject(error)
      }
    }
  },
  mutations: {
    setOrdersStatistics: (state, data) => {
      state.ordersStatisticsDelivery = data.data.delivery
      state.ordersStatisticsCategory = data.data.category
      state.ordersStatisticsPerMonth = data.data.perMonth
      state.categoryList = data.data.categoryList
      state.deliveryList = data.data.deliveryList
    },
    setOrdersList: (state, data) => {
      state.ordersList = data.data.orders
      state.ordersMeta = data.meta
      state.orderStatusList = data.data['order-statuses']
    },
    setOrder: (state, data) => {
      state.order = data.data.order
      state.orderStatusList = data.data.statuses
    },
    setOrderStatusesAll: (state, data) => {
      state.orderStatusList = data.data
    },
    setCostDelivery: (state, data) => {
      state.costDelivery = data.data
    }
  },
  getters: {
    getOrdersList: state => state.ordersList,
    getOrdersMeta: state => state.ordersMeta,
    getOrderStatusList: state => state.orderStatusList,
    getOrder: state => state.order,
    getOrdersStatisticsDelivery: state => state.ordersStatisticsDelivery,
    getOrdersStatisticsCategory: state => state.ordersStatisticsCategory,
    getOrdersStatisticsPerMonth: state => state.ordersStatisticsPerMonth,
    getDeliveryList: state => state.deliveryList,
    getCategoryList: state => state.categoryList,
    getCostDelivery: (state) => state.costDelivery
  },
  state: {
    ordersList: [],
    ordersMeta: [],
    orderStatusList: [],
    order: null,
    ordersStatisticsDelivery: [],
    ordersStatisticsCategory: [],
    ordersStatisticsPerMonth: [],
    deliveryList: [],
    categoryList: [],
    costDelivery: 0
  }
}
