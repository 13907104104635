// Additional polyfills
import 'custom-event-polyfill'
import 'url-polyfill'

import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import axios from 'axios'

import BootstrapVue from 'bootstrap-vue'
import Notifications from 'vue-notification'

import globals from './globals'
import Popper from 'popper.js'

// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(Notifications)

// Global RTL flag
Vue.mixin({
  data: globals
})

axios.defaults.headers.common['Accept-Language'] = 'ru'
axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_API
// Authorization
const token = localStorage.getItem('pf_user_token')
if (token) {
  // eslint-disable-next-line
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
